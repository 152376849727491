<template>
    <section>
        <p class="product__info">{{ $t('realForceInfo.desc') }}</p>
        <UiLoader v-if="!dataReady" theme="block" :loading="true" />
        
        <MatrixReferralTree v-if="dataReady" :user="userData" />

    </section>
</template>  
<script>
import { mapGetters, mapActions } from 'vuex'
import pageReadyByConnect from '@/mixin/pageReadyByConnect'
import MatrixReferralTree from '@/components/Matrix/ReferralTree.vue'

export default {
    data() {
        return {
            userData: null,
        }
    },

    components: {
        MatrixReferralTree,
    },

    mixins: [pageReadyByConnect],

    async mounted() {
        if (this.isConnected) {
            await this.pageReady()
        }
    },

    computed: {
        dataReady() {
            return this.userData
        },
        ...mapGetters('crypto', ['getAccount']),
    },

    methods: {
        async pageReady() {
            const [regErr, regParent] = await this.checkRegistration(true)
            if (regErr) {
                this.$swal(this.$t('errors.registrationError'))
                await this.$router.push({ name: 'signup' })
                return
            }

            const accountQuery = this.$route.query.account
            const targetAccount = accountQuery || this.getAccount

            try {
                let userResponse = await this.guessUser(targetAccount)
                if (!userResponse) throw new Error()

                this.userData = userResponse
        
            } catch (err) {
                this.$swal(this.$t('errors.userNotFound'))
                await this.$router.push({ name: 'dashboard' })
            }
        },

        ...mapActions('crypto', ['checkRegistration']),
        ...mapActions('user', ['guessUser']),
    },
}
</script>  